<script setup lang="ts">
  //TODO: check if observer works as intended
  const emit = defineEmits(['appear', 'disappear', 'update']);

  const target = ref<HTMLElement | null>(null);

  const { stop } = useIntersectionObserver(target, ([entry]) => {
    if (entry.isIntersecting) {
      emit('appear');
    } else {
      emit('disappear');
    }
  });

  onBeforeUnmount(() => {
    stop();
  });
</script>

<template>
  <div ref="target">
    <slot />
  </div>
</template>
